import React, { useEffect } from "react";
import Slider from "react-slick";
import { GetTestimonialData } from "components/ApiServices/GetTestimonialsData";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FullTestimonials = (...props) => {
  const getTestimonials = GetTestimonialData();

  const [slider, setSlider] = useState();
  const [testimonialList, setTestimonialList] = useState(null);

  const _resolveTestimonials = (data) => {
    let tempData = [];
    if (data && data !== "") {
      data.forEach((testimonial) => {
        if (testimonial.node.clientName !== "testing") {
          tempData.push(testimonial);
        }
      });
    }
    return tempData;
  };

  useEffect(() => {
    const resolvedData = _resolveTestimonials(getTestimonials);
    setTestimonialList(resolvedData);
  }, [getTestimonials]);

  const renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => slider.slickNext()}
            className="next cursor-pointer"
          >
            <span className="pe-7s-angle-right"></span>
          </div>
          <div
            onClick={() => slider.slickPrev()}
            className="prev cursor-pointer"
          >
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section
      className={`testimonials ${
        props[0].withIMG
          ? "section-padding bg-img"
          : props[0].withCOLOR
          ? "section-padding back-color"
          : props[0].noPadding
          ? ""
          : "section-padding"
      } ${props.classText ? props.classText : ""}`}
      style={{
        backgroundImage: `${
          props[0].withIMG ? "url(/img/testim-img.jpg)" : "none"
        }`,
      }}
    >
      {/* {props.showHead && ( */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Testimonials
              </h6>
              <h3 className="wow color-font">
                We love our clients from all over the world.
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/*  )} */}

      <div className="container-fluid position-re">
        <div className="row wow fadeInUp" data-wow-delay=".5s">
          <div className="col-lg-12">
            <Slider
              className="slic-item"
              {...{
                ref: (c) => setSlider(c),
                dots: false,
                infinite: true,
                arrows: true,
                centerMode: true,
                autoplay: false,
                rows: 1,
                slidesToScroll: 1,
                slidesToShow: 3,
                centerPadding: 0,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 1,
                      centerMode: false,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      centerMode: false,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      centerMode: false,
                    },
                  },
                ],
              }}
            >
              {testimonialList?.map((valu, i) => {
                return (
                  <div className="item" key={i}>
                    <div className="info valign">
                      <div className="cont">
                        <div className="author">
                          <div className="img">
                            <img className="aspect-ratio" loading ="lazy"
                              src={valu.node?.clientImage?.guid}
                              alt={valu.node.altText}
                            />
                          </div>
                          <h6 className="author-name color-font">
                            {valu.node.clientName}
                          </h6>
                          <span className="author-details">
                            {valu.node.clientDesignation}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>{valu.node.clientTestimonial}</p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        {renderArrows()}
      </div>
    </section>
  );
};

export default FullTestimonials;
